/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

body {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

* {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

::-webkit-scrollbar {
    display: none !important;
}


@font-face {
    font-family: 'cookie';
    src: url('assets/fonts/cookie.ttf');
}

@font-face {
    font-family: 'frekdoka';
    src: url('assets/fonts/fredoka.ttf');
}
 


ion-title{
    font-family: 'cookie';
}

ion-button{
    font-family: 'cookie';
}

ion-text{
    font-family: 'frekdoka' !important;
}

ion-label {
    font-family: 'frekdoka' !important;
}

.my-custom-class {
    --background: #00000000; 

    &::part(content) { 
        backdrop-filter: blur(1px); 
    } 

    ion-content {
        --background: transparent; 
    }
}

ion-modal.productModal{
    
    --height: 90vh;
    background: #00000070;
    --border-radius: 16px;
}

ion-button {
    font-family: 'frekdoka' !important;
}

.additionalDetailsClass {
    --background: #00000000;
     
    ion-content {
        --background: transparent;
    }
}

.toasting{
    text-align: center;
}
 
.loadingRow{
    height: 100%;
} 
 
 

